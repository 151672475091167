import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import QRCode from "react-qr-code";
import { useSelector, useDispatch } from "react-redux";
import { createWalletApi, getDepositHistory } from "src/util/userCallApi";
import { showToast } from "src/function/showToast";
import { Pagination } from "antd";
import { api_status } from "src/constant";
import { Spin } from "antd";
import { coinCreateWallet } from "src/redux/actions/Coin.action";
function SwaptobeWalletDeposit() {
  const coinDropdownData = [
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC",
      type: "Bitcoin",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 2",
      type: "Bitcoin 2",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 3",
      type: "Bitcoin 3",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 4",
      type: "Bitcoin 4",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 5",
      type: "Bitcoin 5",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 6",
      type: "Bitcoin 6",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 7",
      type: "Bitcoin 7",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 8",
      type: "Bitcoin 8",
    },
    {
      imgUrl: "./img/iconvnd.png",
      key: "BTC 9",
      type: "Bitcoin 9",
    },
  ];
  const networkDropdownData = [
    {
      key: "BTC",
      type: "Bitcoin",
      time: "41",
      confirm: "2",
      decription: "thong tin",
    },
    {
      key: "BTC 2",
      type: "Bitcoin 2",
      time: "42",
      confirm: "3",
      decription: "thong tin",
    },
    {
      key: "BTC 3",
      type: "Bitcoin 3",
      time: "43",
      confirm: "4",
      decription: "thong tin",
    },
    {
      key: "BTC 4",
      type: "Bitcoin 4",
      time: "44",
      confirm: "5",
      decription: "thong tin",
    },
    {
      key: "BTC 5",
      type: "Bitcoin 5",
      time: "45",
      confirm: "6",
      decription: "thong tin",
    },
    {
      key: "BTC 6",
      type: "Bitcoin 6",
      time: "46",
      confirm: "6",
      decription: "thong tin",
    },
    {
      key: "BTC 7",
      type: "Bitcoin ",
      time: "47",
      confirm: "7",
      decription: "thong tin",
    },
    {
      key: "BTC 8",
      type: "Bitcoin 8",
      time: "48",
      confirm: "8",
      decription: "thong tin",
    },
    {
      key: "BTC 9",
      type: "Bitcoin 9",
      time: "49",
      confirm: "9",
      decription: "thong tin",
    },
  ];
  //
  const dispatch = useDispatch();
  const [isShowDropdownSelectCoin, setIsShowDropdownSelectCoin] =
    useState(false);
  const [callApiStatusAddress, setCallApiStatusAddress] = useState(
    api_status.pending
  );
  const [callApiStatusHistory, setCallApiStatusHistory] = useState(
    api_status.pending
  );
  const [depositeHistoryTotalPage, setDepositeHistoryTotalPage] = useState(1);
  const [depositeHistoryCurrentPage, setDepositeHistoryCurrentPage] =
    useState(1);
  const [depositeHistory, setDepositeHistory] = useState([]);
  const [selectedCoin, setSelectedCoid] = useState(coinDropdownData[0]);
  const [isShowDropdownNetwork, setIsShowDropdownNetwork] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(
    networkDropdownData[0]
  );
  const [addressCode, setAddressCode] = useState("");
  const coin = useSelector(
    (state) => state.swaptobeWalletReducer.selectedCurrency
  );
  const addressCodeElement = useRef();
  useEffect(() => {
    //
    const closeDropdownSelectCoin = () => setIsShowDropdownSelectCoin(false);
    const closeDropdownNetwork = () => setIsShowDropdownNetwork(false);
    window.addEventListener("click", closeDropdownSelectCoin);
    window.addEventListener("click", closeDropdownNetwork);
    //
    if (coin) {
      // call api lay address
      setCallApiStatusAddress(api_status.fetching);
      createWalletApi(coin)
        .then((dataResponse) => {
          setAddressCode(dataResponse.data.data.address);
          dispatch(coinCreateWallet());
          setCallApiStatusAddress(api_status.fulfilled);
        })
        .catch((error) => {
          setCallApiStatusAddress(api_status.rejected);
          console.log(error);
        });
      //call api lay history deposite
      setCallApiStatusHistory(api_status.fetching);
      getDepositHistory({
        limit: 10,
        page: depositeHistoryCurrentPage,
        symbol: coin,
      })
        .then((resp) => {
          setDepositeHistoryTotalPage(resp.data.data.total);
          setDepositeHistory(resp.data.data.array);
          setCallApiStatusHistory(api_status.fulfilled);
        })
        .catch((error) => {
          setCallApiStatusHistory(api_status.rejected);
          console.log(error);
        });
    }
    return () => {
      window.removeEventListener("click", closeDropdownSelectCoin);
      window.addEventListener("click", closeDropdownNetwork);
    };
  }, []);
  const { t } = useTranslation();
  //
  const renderDropdownCoinMenu = (coinDropdownData) =>
    coinDropdownData.map((dropdownItem) => (
      <li
        key={dropdownItem.key}
        onClick={() => {
          setSelectedCoid(dropdownItem);
        }}
        className="dropdown-item-coin"
      >
        <img src={dropdownItem.imgUrl} alt={dropdownItem.key} />
        <span className="dropdown-item-key">{dropdownItem.key}</span>
        <span>{dropdownItem.type}</span>
      </li>
    ));
  const renderDropdownNetworkMenu = (networkDropdownData) =>
    networkDropdownData.map((dropdownItem) => (
      <li
        key={dropdownItem.key}
        onClick={() => {
          setSelectedNetwork(dropdownItem);
        }}
        className="dropdown-item-network"
      >
        <div className="dropdown-item-network-left">
          <span>{dropdownItem.key}</span>
          <span>{dropdownItem.type}</span>
        </div>
        <div className="dropdown-item-network-right">
          <span>≈{dropdownItem.time} mins</span>
          <span>{dropdownItem.confirm} Confirmation/s</span>
        </div>
      </li>
    ));
  const selectCoinDropdownCLickHandle = (e) => {
    e.stopPropagation();
    setIsShowDropdownSelectCoin((s) => !s);
  };
  const selectNetworkDropdownCLickHandle = (e) => {
    e.stopPropagation();
    setIsShowDropdownNetwork((s) => !s);
  };
  const copyAddressClickHandle = () => {
    const addressCode = addressCodeElement.current.innerHTML;
    const writeTexttoClipboard = navigator.clipboard.writeText(addressCode);
    writeTexttoClipboard.then(() => {
      showToast("success", "Copy thành công");
    });
  };
  const renderHistoryDeposit = function (arrData) {
    return (
      <div className="wallet-deposit__history fadeInBottomToTop">
        {arrData && arrData.length ? (
          arrData.map((item) => (
            <div key={item.coin_key} className="wallet-deposite__history-item">
              <div className="wallet-deposite__history-time">
                <i className="fa-solid fa-calendar"></i> {item.created_at}
              </div>
              <div className="wallet-deposite__history-name">
                {(item.coin_key ?? "").toUpperCase()}
              </div>
              <div className="wallet-deposite__history-amount">
                +{item.amount} coins
              </div>
              <div className="wallet-deposite__history-final">
                <span>Final Amount:</span>
                <span>{item.before_amount} coins</span>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    );
  };
  //
  return (
    <>
      <div className="container">
        <div className="wallet-deposit fadeInBottomToTop">
          <div className="wallet-deposit-left">
            <ul>
              <li>
                <span className="number">1</span>
                <div className="wallet-deposit-input">
                  <p>{t("select")} Coin</p>
                  <div
                    onClick={selectCoinDropdownCLickHandle}
                    className="wallet-deposit-dropdown"
                  >
                    <div className="dropdown-content">
                      <img src={selectedCoin.imgUrl} alt={selectedCoin.key} />
                      <span className="content">
                        <span className="main-content">{selectedCoin.key}</span>
                        {selectedCoin.type}
                      </span>
                      <span className="down-icon">
                        <i className="fa-solid fa-caret-down"></i>
                      </span>
                    </div>
                    <div
                      style={
                        isShowDropdownSelectCoin ? { display: "block" } : {}
                      }
                      className="dropdown-menu-container"
                    >
                      <ul className="dropdown-menu">
                        {renderDropdownCoinMenu(coinDropdownData)}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <span className="number">2</span>
                <div className="wallet-deposit-input">
                  <p>{t("select")} Network</p>
                  <div
                    onClick={selectNetworkDropdownCLickHandle}
                    className="wallet-deposit-dropdown"
                  >
                    <div className="dropdown-content">
                      <span className="content">
                        <span className="main-content">
                          {selectedNetwork.key}
                        </span>
                        {selectedNetwork.decription}
                      </span>
                      <span className="down-icon">
                        <i className="fa-solid fa-caret-down"></i>
                      </span>
                    </div>
                    <div
                      style={isShowDropdownNetwork ? { display: "block" } : {}}
                      className=" dropdown-menu-container"
                    >
                      <ul className="dropdown-menu">
                        {renderDropdownNetworkMenu(networkDropdownData)}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <span className="number">3</span>
                <div className="address">
                  <p>
                    Desposite Address
                    <span>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                  </p>
                  {callApiStatusAddress === api_status.fetching && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spin />
                    </div>
                  )}
                  {callApiStatusAddress !== api_status.fetching &&
                    addressCode && (
                      <>
                        <div className="address-content">
                          <div className={`fadeInBottomToTop`}>
                            <QRCode
                              style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                              value={addressCode}
                            />
                          </div>
                          <div className="address-code fadeInBottomToTop">
                            <div className="address-code-title">address</div>
                            <div ref={addressCodeElement} className="code">
                              {addressCode}
                            </div>
                          </div>
                          <span
                            onClick={copyAddressClickHandle}
                            className="address-copy fadeInBottomToTop"
                          >
                            <i className="fa-regular fa-copy"></i>
                          </span>
                        </div>
                      </>
                    )}
                </div>
              </li>
            </ul>
          </div>
          <div className="wallet-deposit-right">
            <h3>Desposite BTC history</h3>
            {callApiStatusHistory === api_status.fetching && (
              <div
                className="fadeInBottomToTop"
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
            )}
            {callApiStatusHistory !== api_status.fetching &&
              !depositeHistory.length && <Empty />}
            {callApiStatusHistory !== api_status.fetching &&
              depositeHistory.length > 0 &&
              renderHistoryDeposit(depositeHistory)}
            <div className="wallet-deposite-paging">
              <Pagination defaultCurrent={1} total={10} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SwaptobeWalletDeposit;
