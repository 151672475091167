import { axiosService } from "./service";

export const createWalletApi = function (coinName) {
  try {
    return axiosService.post("/api/user/createWallet", {
      symbol: coinName,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getWalletApi = function () {
  try {
    return axiosService.post("/api/user/getWallet");
  } catch (error) {
    console.log(error);
  }
};

export const getHistoryswap = function (data) {
  try {
    return axiosService.post("/api/swap/historyswap", data);
  } catch (error) {}
};

export const getDepositHistory = function (data) {
  try {
    return axiosService.post("/api/blockico/getblocks", data);
  } catch (error) {
    console.log(error);
  }
};
