import React from "react";

function Dashboard() {
  const dataTree = [
    {
      id: 2,
      userName: "hhuuthien",
      email: "hhuuthien@gmail.com",
      referral: "bf3b1f421101",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-08-26T07:40:36.000Z",
      block: 0,
      twofa: 0,
      balance: 271.20000000000005,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 3,
      userName: "test",
      email: "test@gmail.com",
      referral: "49c59892305f",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-08-26T07:39:55.000Z",
      block: 0,
      twofa: 1,
      balance: 3582.527499999979,
      demoBalance: 1294.5,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 1,
      admin: 1,
      totalOrder: 2845,
      totalOrderF1: 369224.5,
      commissionMemberVip: 87.5,
      totalMemberVip: 3,
      totalMemberVipF1: 1,
      totalMember: 1,
      beforeTotalOrder: 2845,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 3562.74,
      beforeCommission: 0.1,
      avatar: "/images/image-1688113630013.jpeg",
      array: [
        {
          id: 5,
          userName: "testf1",
          email: "testf1@gmail.com",
          referral: "080ffd6545d7",
          parentId: 3,
          userNameParent: "test",
          active: 1,
          created_at: "2022-09-04T09:59:57.000Z",
          block: 0,
          twofa: 1,
          balance: 9390.3,
          demoBalance: 1000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 356274,
          totalOrderF1: 10339.54,
          commissionMemberVip: 75,
          totalMemberVip: 2,
          totalMemberVipF1: 1,
          totalMember: 0,
          beforeTotalOrder: 356274,
          updateLevel_at: "2023-02-06T08:46:41.000Z",
          totalCommission: 74.10039999999998,
          beforeCommission: 0.1,
          avatar: null,
          array: [
            {
              id: 6,
              userName: "testf2",
              email: "testf2@gmail.com",
              referral: "5c0f2575210f",
              parentId: 5,
              userNameParent: "testf1",
              active: 1,
              created_at: "2022-09-05T14:20:31.000Z",
              block: 0,
              twofa: 0,
              balance: 9970,
              demoBalance: 1000,
              level: 1,
              commissionBalance: 0,
              deposit: 0,
              blockLevel: 0,
              trade: 0,
              double10: 0,
              marketing: 0,
              type: 0,
              admin: 0,
              totalOrder: 7410.04,
              totalOrderF1: 133,
              commissionMemberVip: 50,
              totalMemberVip: 1,
              totalMemberVipF1: 1,
              totalMember: 0,
              beforeTotalOrder: 7410.04,
              updateLevel_at: "2023-02-06T08:46:41.000Z",
              totalCommission: 0,
              beforeCommission: 0,
              avatar: null,
              array: [
                {
                  id: 7,
                  userName: "testf3",
                  email: "testf3@gmail.com",
                  referral: "11c51f12d829",
                  parentId: 6,
                  userNameParent: "testf2",
                  active: 1,
                  created_at: "2022-09-05T14:44:22.000Z",
                  block: 0,
                  twofa: 1,
                  balance: 100048.01,
                  demoBalance: 10000,
                  level: 1,
                  commissionBalance: 0,
                  deposit: 0,
                  blockLevel: 0,
                  trade: 0,
                  double10: 1,
                  marketing: 0,
                  type: 0,
                  admin: 0,
                  totalOrder: 0,
                  totalOrderF1: 0,
                  commissionMemberVip: 0,
                  totalMemberVip: 0,
                  totalMemberVipF1: 0,
                  totalMember: 0,
                  beforeTotalOrder: 0,
                  updateLevel_at: "2023-02-06T08:46:41.000Z",
                  totalCommission: 0,
                  beforeCommission: 0,
                  avatar: null,
                  array: [
                    {
                      id: 8,
                      userName: "lucas",
                      email: "lucas@gmail.com",
                      referral: "6c21e37861e7",
                      parentId: 7,
                      userNameParent: "testf3",
                      active: 1,
                      created_at: "2022-09-07T04:01:34.000Z",
                      block: 0,
                      twofa: 0,
                      balance: 453177,
                      demoBalance: 10000,
                      level: 0,
                      commissionBalance: 0,
                      deposit: 0,
                      blockLevel: 0,
                      trade: 0,
                      double10: 0,
                      marketing: 0,
                      type: 0,
                      admin: 0,
                      totalOrder: 0,
                      totalOrderF1: 0,
                      commissionMemberVip: 0,
                      totalMemberVip: 0,
                      totalMemberVipF1: 0,
                      totalMember: 0,
                      beforeTotalOrder: 0,
                      updateLevel_at: "2023-02-06T08:46:41.000Z",
                      totalCommission: 0,
                      beforeCommission: 0,
                      avatar: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 21,
          userName: "Trade68",
          email: "muaban6868@gmail.com",
          referral: "22fb3e63103a",
          parentId: 3,
          userNameParent: "test",
          active: 1,
          created_at: "2022-12-10T09:55:01.000Z",
          block: 0,
          twofa: 0,
          balance: 8963.45,
          demoBalance: 10000,
          level: 0,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-06T08:46:41.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
        {
          id: 27,
          userName: "nick101",
          email: "grwgwefq@gmail.com",
          referral: "80d816c255f3",
          parentId: 3,
          userNameParent: "test",
          active: 1,
          created_at: "2023-02-09T05:16:16.000Z",
          block: 0,
          twofa: 0,
          balance: 0,
          demoBalance: 10000,
          level: 0,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-09T05:16:16.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
      ],
    },
    {
      id: 4,
      userName: "test101",
      email: "test101@gmail.com",
      referral: "2c879f1853b0",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-08-30T01:41:15.000Z",
      block: 0,
      twofa: 0,
      balance: 100,
      demoBalance: 10230.600000000042,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 1,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
      array: [
        {
          id: 101,
          userName: "ttest1",
          email: "ttest1@gmail.com",
          referral: "45436c",
          parentId: 4,
          userNameParent: "test101",
          active: 0,
          created_at: "2023-09-23T04:21:43.000Z",
          block: 0,
          twofa: 0,
          balance: 0,
          demoBalance: 10000,
          level: 0,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-09-23T04:21:43.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
        {
          id: 102,
          userName: "tttest1",
          email: "tttest1@gmail.com",
          referral: "8dd59b",
          parentId: 4,
          userNameParent: "test101",
          active: 1,
          created_at: "2023-09-23T04:22:03.000Z",
          block: 0,
          twofa: 0,
          balance: 0,
          demoBalance: 10000,
          level: 0,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-09-23T04:22:03.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
      ],
    },
    {
      id: 9,
      userName: "testblocked",
      email: "test_blocked@gmail.com",
      referral: "a32e3c3d5f7a",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-09-07T08:32:35.000Z",
      block: 0,
      twofa: 1,
      balance: 508,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 10,
      userName: "testf4",
      email: "testf4@gmail.com",
      referral: "f840ef871b3b",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-09-08T02:07:46.000Z",
      block: 0,
      twofa: 1,
      balance: 7,
      demoBalance: 1000,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 11,
      userName: "Lucky",
      email: "canvaprovinhvien@gmail.com",
      referral: "d3257bc282fc",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-09-08T06:27:57.000Z",
      block: 0,
      twofa: 1,
      balance: 121165.96114892888,
      demoBalance: 12726.1,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
      array: [
        {
          id: 20,
          userName: "sakami",
          email: "alaka@gmail.com",
          referral: "f0c39d0f9407",
          parentId: 11,
          userNameParent: "Lucky",
          active: 1,
          created_at: "2022-12-07T15:37:03.000Z",
          block: 0,
          twofa: 0,
          balance: 40449.94999998932,
          demoBalance: 10000,
          level: 0,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-06T08:46:41.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
      ],
    },
    {
      id: 12,
      userName: "dvkien",
      email: "dvkin2803@gmail.com",
      referral: "02cf6484e8c6",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-09-08T10:16:05.000Z",
      block: 0,
      twofa: 1,
      balance: 793336.6599999997,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 1,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 13,
      userName: "admin1234",
      email: "duyduyvtp@gmail.com",
      referral: "c93848452d43",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-11-21T08:35:55.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 14,
      userName: "hoang anh",
      email: "nguyenduylt779@gmail.com",
      referral: "c87921f798d8",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-11-21T08:37:02.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 15,
      userName: "okeoke99",
      email: "dell010916@gmail.com",
      referral: "539e2a910897",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-11-21T08:41:05.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 16,
      userName: "gmail",
      email: "gmail@gmail.com",
      referral: "584e4e3f6b7b",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-11-24T18:40:40.000Z",
      block: 0,
      twofa: 0,
      balance: 700,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 17,
      userName: "Như Ý",
      email: "Dauchamhoi123@gmail.com",
      referral: "53c1a8e95a31",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-11-25T12:05:54.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 18,
      userName: "truongbe1",
      email: "kkkk123@gmail.com",
      referral: "876b351a481b",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-11-25T12:10:52.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 19,
      userName: "nguyenphuquy",
      email: "nguyenphuquy15051990@gmail.com",
      referral: "19666ad265b8",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-11-25T12:14:57.000Z",
      block: 0,
      twofa: 0,
      balance: 10,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 22,
      userName: "Duyanh",
      email: "pduyanh557@gmail.com",
      referral: "0986a9afd0a6",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2022-12-23T17:06:48.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 23,
      userName: "Oahkk",
      email: "tilovemc98@gmail.com",
      referral: "fe2ef1a85eed",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2022-12-26T15:35:23.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-06T08:46:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 24,
      userName: "wallet",
      email: "wallet@gmail.com",
      referral: "f9be14c91198",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-07T05:12:48.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-07T05:12:48.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 25,
      userName: "testdas",
      email: "testwallet@gmail.com",
      referral: "5bef5f5f5be8",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-07T05:23:07.000Z",
      block: 0,
      twofa: 1,
      balance: 9878.442558943374,
      demoBalance: 9990,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 40,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 40,
      updateLevel_at: "2023-02-07T05:23:07.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 26,
      userName: "nick12",
      email: "frwfeewfwe@gmail.com",
      referral: "8dc42c58ad8a",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-09T05:05:32.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-09T05:05:32.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 28,
      userName: "testuse",
      email: "testuser@gmail.com",
      referral: "92c7a13cbff6",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-09T05:20:33.000Z",
      block: 0,
      twofa: 1,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-09T05:20:33.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 29,
      userName: "nick102",
      email: "gggereger@gmail.com",
      referral: "316979e49de6",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-09T05:30:20.000Z",
      block: 0,
      twofa: 0,
      balance: 1228.7501968749891,
      demoBalance: 19500,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 59030,
      commissionMemberVip: 353.11,
      totalMemberVip: 14,
      totalMemberVipF1: 6,
      totalMember: 10,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-09T05:30:20.000Z",
      totalCommission: 923.8483218750008,
      beforeCommission: 313.15345312500034,
      avatar: null,
      array: [
        {
          id: 30,
          userName: "nick103",
          email: "grgqqq3frqfq3@gmail.com",
          referral: "a0f89d6ae70b",
          parentId: 29,
          userNameParent: "nick102",
          active: 1,
          created_at: "2023-02-09T08:09:35.000Z",
          block: 0,
          twofa: 0,
          balance: 1624.81208125,
          demoBalance: 10000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 1000,
          commissionMemberVip: 107.02,
          totalMemberVip: 9,
          totalMemberVipF1: 1,
          totalMember: 4,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-09T08:09:35.000Z",
          totalCommission: 664.0383312500005,
          beforeCommission: 626.3069062500007,
          avatar: null,
          array: [
            {
              id: 31,
              userName: "nick104",
              email: "grwgq3r3qr3s@gmail.com",
              referral: "5f2a6977ea13",
              parentId: 30,
              userNameParent: "nick103",
              active: 1,
              created_at: "2023-02-09T08:29:16.000Z",
              block: 0,
              twofa: 0,
              balance: 2289.6241624999952,
              demoBalance: 10000,
              level: 1,
              commissionBalance: 0,
              deposit: 0,
              blockLevel: 0,
              trade: 0,
              double10: 0,
              marketing: 0,
              type: 0,
              admin: 0,
              totalOrder: 0,
              totalOrderF1: 1520,
              commissionMemberVip: 114.05,
              totalMemberVip: 8,
              totalMemberVipF1: 1,
              totalMember: 4,
              beforeTotalOrder: 0,
              updateLevel_at: "2023-02-09T08:29:16.000Z",
              totalCommission: 1308.0766625000017,
              beforeCommission: 1252.6138125000014,
              avatar: null,
              array: [
                {
                  id: 33,
                  userName: "nick105",
                  email: "ggfafasdw@gmail.com",
                  referral: "670334aa89bf",
                  parentId: 31,
                  userNameParent: "nick104",
                  active: 1,
                  created_at: "2023-02-09T09:42:11.000Z",
                  block: 0,
                  twofa: 0,
                  balance: 3165.858325000001,
                  demoBalance: 10000,
                  level: 1,
                  commissionBalance: 0,
                  deposit: 0,
                  blockLevel: 0,
                  trade: 0,
                  double10: 0,
                  marketing: 0,
                  type: 0,
                  admin: 0,
                  totalOrder: 0,
                  totalOrderF1: 1000,
                  commissionMemberVip: 128.12,
                  totalMemberVip: 7,
                  totalMemberVipF1: 1,
                  totalMember: 4,
                  beforeTotalOrder: 0,
                  updateLevel_at: "2023-02-09T09:42:11.000Z",
                  totalCommission: 2585.7533250000024,
                  beforeCommission: 2505.2276250000027,
                  avatar: null,
                  array: [
                    {
                      id: 34,
                      userName: "nick106",
                      email: "kgjghjter@gmail.com",
                      referral: "db6ff1eec399",
                      parentId: 33,
                      userNameParent: "nick105",
                      active: 1,
                      created_at: "2023-02-09T09:54:23.000Z",
                      block: 0,
                      twofa: 0,
                      balance: 6137.716650000001,
                      demoBalance: 10000,
                      level: 1,
                      commissionBalance: 0,
                      deposit: 0,
                      blockLevel: 0,
                      trade: 0,
                      double10: 0,
                      marketing: 0,
                      type: 0,
                      admin: 0,
                      totalOrder: 0,
                      totalOrderF1: 10071.790000000005,
                      commissionMemberVip: 156.25,
                      totalMemberVip: 6,
                      totalMemberVipF1: 2,
                      totalMember: 5,
                      beforeTotalOrder: 0,
                      updateLevel_at: "2023-02-09T09:54:23.000Z",
                      totalCommission: 5184.023150000005,
                      beforeCommission: 5010.455250000005,
                      avatar: null,
                      array: [
                        {
                          id: 35,
                          userName: "nick107",
                          email: "htdhdtvsfs@gmail.com",
                          referral: "0d7e24d711bf",
                          parentId: 34,
                          userNameParent: "nick106",
                          active: 1,
                          created_at: "2023-02-09T09:57:42.000Z",
                          block: 0,
                          twofa: 0,
                          balance: 11176.610500000008,
                          demoBalance: 10000,
                          level: 1,
                          commissionBalance: 0,
                          deposit: 0,
                          blockLevel: 0,
                          trade: 0,
                          double10: 0,
                          marketing: 0,
                          type: 0,
                          admin: 0,
                          totalOrder: 0,
                          totalOrderF1: 1000,
                          commissionMemberVip: 112.5,
                          totalMemberVip: 4,
                          totalMemberVipF1: 1,
                          totalMember: 4,
                          beforeTotalOrder: 0,
                          updateLevel_at: "2023-02-09T09:57:42.000Z",
                          totalCommission: 10166.610500000012,
                          beforeCommission: 10020.91050000001,
                          avatar: null,
                          array: [
                            {
                              id: 37,
                              userName: "nick108",
                              email: "fefqwqfqefeq@gmail.com",
                              referral: "4552f14853be",
                              parentId: 35,
                              userNameParent: "nick107",
                              active: 1,
                              created_at: "2023-02-09T10:12:03.000Z",
                              block: 0,
                              twofa: 0,
                              balance: 21353.221000000016,
                              demoBalance: 10000,
                              level: 1,
                              commissionBalance: 0,
                              deposit: 0,
                              blockLevel: 0,
                              trade: 0,
                              double10: 0,
                              marketing: 0,
                              type: 0,
                              admin: 0,
                              totalOrder: 0,
                              totalOrderF1: 2041482.0999999985,
                              commissionMemberVip: 125,
                              totalMemberVip: 3,
                              totalMemberVipF1: 2,
                              totalMember: 7,
                              beforeTotalOrder: 0,
                              updateLevel_at: "2023-02-09T10:12:03.000Z",
                              totalCommission: 20414.82100000002,
                              beforeCommission: 20041.82100000002,
                              avatar: null,
                              array: [
                                {
                                  id: 38,
                                  userName: "nick109",
                                  email: "hfghrhre@gmail.com",
                                  referral: "bb2244881892",
                                  parentId: 37,
                                  userNameParent: "nick108",
                                  active: 1,
                                  created_at: "2023-02-09T10:24:13.000Z",
                                  block: 0,
                                  twofa: 0,
                                  balance: 1233,
                                  demoBalance: 1000,
                                  level: 1,
                                  commissionBalance: 0,
                                  deposit: 0,
                                  blockLevel: 0,
                                  trade: 0,
                                  double10: 0,
                                  marketing: 0,
                                  type: 0,
                                  admin: 0,
                                  totalOrder: 0,
                                  totalOrderF1: 32000,
                                  commissionMemberVip: 50,
                                  totalMemberVip: 1,
                                  totalMemberVipF1: 1,
                                  totalMember: 4,
                                  beforeTotalOrder: 0,
                                  updateLevel_at: "2023-02-09T10:24:13.000Z",
                                  totalCommission: 220,
                                  beforeCommission: 200,
                                  avatar: null,
                                  array: [
                                    {
                                      id: 51,
                                      userName: "nick116",
                                      email: "rgeargw24r@gmail.com",
                                      referral: "0402d1",
                                      parentId: 38,
                                      userNameParent: "nick109",
                                      active: 1,
                                      created_at: "2023-02-16T13:25:18.000Z",
                                      block: 0,
                                      twofa: 0,
                                      balance: 0,
                                      demoBalance: 19500,
                                      level: 0,
                                      commissionBalance: 0,
                                      deposit: 0,
                                      blockLevel: 0,
                                      trade: 0,
                                      double10: 0,
                                      marketing: 0,
                                      type: 0,
                                      admin: 0,
                                      totalOrder: 0,
                                      totalOrderF1: 0,
                                      commissionMemberVip: 0,
                                      totalMemberVip: 0,
                                      totalMemberVipF1: 0,
                                      totalMember: 0,
                                      beforeTotalOrder: 0,
                                      updateLevel_at:
                                        "2023-02-16T13:25:18.000Z",
                                      totalCommission: 0,
                                      beforeCommission: 0,
                                      avatar: null,
                                    },
                                    {
                                      id: 53,
                                      userName: "nick118",
                                      email: "gaeigjo312@gmail.com",
                                      referral: "1444ee",
                                      parentId: 38,
                                      userNameParent: "nick109",
                                      active: 1,
                                      created_at: "2023-02-17T04:30:12.000Z",
                                      block: 0,
                                      twofa: 0,
                                      balance: 2900,
                                      demoBalance: 10000,
                                      level: 1,
                                      commissionBalance: 0,
                                      deposit: 0,
                                      blockLevel: 0,
                                      trade: 0,
                                      double10: 0,
                                      marketing: 0,
                                      type: 0,
                                      admin: 0,
                                      totalOrder: 0,
                                      totalOrderF1: 0,
                                      commissionMemberVip: 0,
                                      totalMemberVip: 0,
                                      totalMemberVipF1: 0,
                                      totalMember: 0,
                                      beforeTotalOrder: 0,
                                      updateLevel_at:
                                        "2023-02-17T04:30:12.000Z",
                                      totalCommission: 0,
                                      beforeCommission: 0,
                                      avatar: null,
                                    },
                                    {
                                      id: 57,
                                      userName: "tang7",
                                      email: "rwgwa423432@gmail.com",
                                      referral: "74e31c",
                                      parentId: 38,
                                      userNameParent: "nick109",
                                      active: 1,
                                      created_at: "2023-02-23T04:22:10.000Z",
                                      block: 0,
                                      twofa: 0,
                                      balance: 29000,
                                      demoBalance: 10000,
                                      level: 0,
                                      commissionBalance: 0,
                                      deposit: 0,
                                      blockLevel: 0,
                                      trade: 0,
                                      double10: 0,
                                      marketing: 0,
                                      type: 0,
                                      admin: 0,
                                      totalOrder: 0,
                                      totalOrderF1: 0,
                                      commissionMemberVip: 0,
                                      totalMemberVip: 0,
                                      totalMemberVipF1: 0,
                                      totalMember: 0,
                                      beforeTotalOrder: 0,
                                      updateLevel_at:
                                        "2023-02-23T04:22:10.000Z",
                                      totalCommission: 0,
                                      beforeCommission: 0,
                                      avatar: null,
                                    },
                                  ],
                                },
                                {
                                  id: 54,
                                  userName: "nick119",
                                  email: "242frefwefwe@gmail.com",
                                  referral: "4b53f4",
                                  parentId: 37,
                                  userNameParent: "nick108",
                                  active: 1,
                                  created_at: "2023-02-17T04:38:28.000Z",
                                  block: 0,
                                  twofa: 0,
                                  balance: 2931,
                                  demoBalance: 10000,
                                  level: 1,
                                  commissionBalance: 0,
                                  deposit: 0,
                                  blockLevel: 0,
                                  trade: 0,
                                  double10: 0,
                                  marketing: 0,
                                  type: 0,
                                  admin: 0,
                                  totalOrder: 0,
                                  totalOrderF1: 0,
                                  commissionMemberVip: 0,
                                  totalMemberVip: 0,
                                  totalMemberVipF1: 0,
                                  totalMember: 0,
                                  beforeTotalOrder: 0,
                                  updateLevel_at: "2023-02-17T04:38:28.000Z",
                                  totalCommission: 0,
                                  beforeCommission: 0,
                                  avatar: null,
                                },
                                {
                                  id: 58,
                                  userName: "7tang",
                                  email: "t4q3r23413@gmail.com",
                                  referral: "2179af",
                                  parentId: 37,
                                  userNameParent: "nick108",
                                  active: 1,
                                  created_at: "2023-02-23T04:34:56.000Z",
                                  block: 0,
                                  twofa: 0,
                                  balance: 104.05,
                                  demoBalance: 1000,
                                  level: 0,
                                  commissionBalance: 0,
                                  deposit: 0,
                                  blockLevel: 0,
                                  trade: 0,
                                  double10: 0,
                                  marketing: 0,
                                  type: 0,
                                  admin: 0,
                                  totalOrder: 0,
                                  totalOrderF1: 0,
                                  commissionMemberVip: 0,
                                  totalMemberVip: 0,
                                  totalMemberVipF1: 0,
                                  totalMember: 0,
                                  beforeTotalOrder: 0,
                                  updateLevel_at: "2023-02-23T04:34:56.000Z",
                                  totalCommission: 0,
                                  beforeCommission: 0,
                                  avatar: null,
                                },
                                {
                                  id: 59,
                                  userName: "77tang",
                                  email: "grwgwrg4534@gmail.com",
                                  referral: "d8db8f",
                                  parentId: 37,
                                  userNameParent: "nick108",
                                  active: 1,
                                  created_at: "2023-02-24T17:58:02.000Z",
                                  block: 0,
                                  twofa: 0,
                                  balance: 1.408499999999952,
                                  demoBalance: 10000,
                                  level: 0,
                                  commissionBalance: 0,
                                  deposit: 0,
                                  blockLevel: 0,
                                  trade: 0,
                                  double10: 0,
                                  marketing: 0,
                                  type: 0,
                                  admin: 0,
                                  totalOrder: 0,
                                  totalOrderF1: 0,
                                  commissionMemberVip: 0,
                                  totalMemberVip: 0,
                                  totalMemberVipF1: 0,
                                  totalMember: 0,
                                  beforeTotalOrder: 0,
                                  updateLevel_at: "2023-02-24T17:58:02.000Z",
                                  totalCommission: 0,
                                  beforeCommission: 0,
                                  avatar: null,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: 36,
                          userName: "Bemeo",
                          email: "bemeo123@gmail.com",
                          referral: "89e39fee13db",
                          parentId: 34,
                          userNameParent: "nick106",
                          active: 1,
                          created_at: "2023-02-09T10:01:13.000Z",
                          block: 0,
                          twofa: 0,
                          balance: 56802.143,
                          demoBalance: 10000,
                          level: 1,
                          commissionBalance: 0,
                          deposit: 0,
                          blockLevel: 0,
                          trade: 0,
                          double10: 0,
                          marketing: 0,
                          type: 0,
                          admin: 0,
                          totalOrder: 0,
                          totalOrderF1: 0,
                          commissionMemberVip: 0,
                          totalMemberVip: 0,
                          totalMemberVipF1: 0,
                          totalMember: 0,
                          beforeTotalOrder: 0,
                          updateLevel_at: "2023-02-09T10:01:13.000Z",
                          totalCommission: 0,
                          beforeCommission: 0,
                          avatar: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 45,
          userName: "nick110",
          email: "gegergtrhrth4tg3432@gmail.com",
          referral: "7262fa0da567",
          parentId: 29,
          userNameParent: "nick102",
          active: 1,
          created_at: "2023-02-14T09:53:56.000Z",
          block: 0,
          twofa: 0,
          balance: 2900,
          demoBalance: 10000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-14T09:53:56.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
        {
          id: 46,
          userName: "nick111",
          email: "ggtrhrthyrht1423423@gmail.com",
          referral: "d9d1fb47db36",
          parentId: 29,
          userNameParent: "nick102",
          active: 1,
          created_at: "2023-02-14T10:27:18.000Z",
          block: 0,
          twofa: 0,
          balance: 2050,
          demoBalance: 10000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-14T10:27:18.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
        {
          id: 47,
          userName: "Laodai",
          email: "jdi2jfjwhfk@gmail.com",
          referral: "06c57736e970",
          parentId: 29,
          userNameParent: "nick102",
          active: 1,
          created_at: "2023-02-14T10:36:58.000Z",
          block: 0,
          twofa: 0,
          balance: 1,
          demoBalance: 1000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-14T10:36:58.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: "/images/image-1676636878221.png",
        },
        {
          id: 48,
          userName: "nick112",
          email: "regtgryhrt342342@gmail.com",
          referral: "6f18b35c0ed9",
          parentId: 29,
          userNameParent: "nick102",
          active: 1,
          created_at: "2023-02-14T10:43:04.000Z",
          block: 0,
          twofa: 0,
          balance: 57500,
          demoBalance: 10000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-14T10:43:04.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
        {
          id: 49,
          userName: "nicktestvip",
          email: "nicktestvip@gmail.com",
          referral: "559bf47254cd",
          parentId: 29,
          userNameParent: "nick102",
          active: 1,
          created_at: "2023-02-15T05:11:05.000Z",
          block: 0,
          twofa: 0,
          balance: 49900,
          demoBalance: 10000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 2,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-15T05:11:05.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
          array: [
            {
              id: 66,
              userName: "test111",
              email: "test111@gmail.com",
              referral: "ac3426",
              parentId: 49,
              userNameParent: "nicktestvip",
              active: 1,
              created_at: "2023-03-07T08:03:05.000Z",
              block: 0,
              twofa: 0,
              balance: 0,
              demoBalance: 10000,
              level: 0,
              commissionBalance: 0,
              deposit: 0,
              blockLevel: 0,
              trade: 0,
              double10: 0,
              marketing: 0,
              type: 0,
              admin: 0,
              totalOrder: 0,
              totalOrderF1: 0,
              commissionMemberVip: 0,
              totalMemberVip: 0,
              totalMemberVipF1: 0,
              totalMember: 0,
              beforeTotalOrder: 0,
              updateLevel_at: "2023-03-07T08:03:05.000Z",
              totalCommission: 0,
              beforeCommission: 0,
              avatar: null,
            },
          ],
        },
        {
          id: 52,
          userName: "nick117",
          email: "rger23432@gmail.com",
          referral: "ce4c9d",
          parentId: 29,
          userNameParent: "nick102",
          active: 1,
          created_at: "2023-02-17T04:18:40.000Z",
          block: 0,
          twofa: 0,
          balance: 1000,
          demoBalance: 10000,
          level: 1,
          commissionBalance: 0,
          deposit: 0,
          blockLevel: 0,
          trade: 0,
          double10: 0,
          marketing: 0,
          type: 0,
          admin: 0,
          totalOrder: 0,
          totalOrderF1: 0,
          commissionMemberVip: 0,
          totalMemberVip: 0,
          totalMemberVipF1: 0,
          totalMember: 0,
          beforeTotalOrder: 0,
          updateLevel_at: "2023-02-17T04:18:40.000Z",
          totalCommission: 0,
          beforeCommission: 0,
          avatar: null,
        },
      ],
    },
    {
      id: 32,
      userName: "test100",
      email: "test100@gmail.com",
      referral: "1d932542cb57",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-09T09:22:21.000Z",
      block: 0,
      twofa: 0,
      balance: 9009.210028985508,
      demoBalance: 1000,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 1,
      admin: 1,
      totalOrder: 180,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 180,
      updateLevel_at: "2023-02-09T09:22:21.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: "/images/image-1676662248387.png",
    },
    {
      id: 39,
      userName: "test102",
      email: "test102@gmail.com",
      referral: "895abe9bd6a9",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-10T01:33:04.000Z",
      block: 0,
      twofa: 1,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-10T01:33:04.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 40,
      userName: "test103",
      email: "test103@gmail.com",
      referral: "4d1c1dcaa31a",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-10T06:47:48.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-10T06:47:48.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 41,
      userName: "Trader68",
      email: "thienminh082022@gmail.com",
      referral: "5f1f7c42733e",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-10T14:34:05.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10065,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-10T14:34:05.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 42,
      userName: "huyotc",
      email: "jyjtyhrtehtr@gmail.com",
      referral: "6b9921f7ac03",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-13T11:27:57.000Z",
      block: 0,
      twofa: 0,
      balance: 2163.1,
      demoBalance: 1000,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-13T11:27:57.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 43,
      userName: "Test Pro",
      email: "Teambonbontrade@gmail.com",
      referral: "10c2c6f6dead",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-14T09:51:46.000Z",
      block: 0,
      twofa: 0,
      balance: 584.25,
      demoBalance: 9989.5,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-14T09:51:46.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: "/images/image-1677058116124.png",
    },
    {
      id: 44,
      userName: "Testpro",
      email: "cafetaichinhorg@gmail.com",
      referral: "9e9edb613b70",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-14T09:53:37.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-14T09:53:37.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 50,
      userName: "test200",
      email: "test200@gmail.com",
      referral: "5797e8",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-16T05:01:59.000Z",
      block: 0,
      twofa: 0,
      balance: 738.7212765957448,
      demoBalance: 1000,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 240,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 240,
      updateLevel_at: "2023-02-16T05:01:59.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: "/images/image-1676539329119.png",
    },
    {
      id: 55,
      userName: "Nick120",
      email: "jfhsjfhdjjaja@gmail.com",
      referral: "47993c",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-17T19:44:42.000Z",
      block: 0,
      twofa: 0,
      balance: 968,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-17T19:44:42.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 56,
      userName: "Meoxinh",
      email: "Meoxinh@gmail.com",
      referral: "cabc29",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-20T14:50:26.000Z",
      block: 0,
      twofa: 0,
      balance: 7009.9375,
      demoBalance: 10000,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-20T14:50:26.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 60,
      userName: "nguyenvanviet",
      email: "nguyenvanviet@gmail.com",
      referral: "4b7dbe",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-28T17:33:15.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-28T17:33:15.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 61,
      userName: "buiduy886ty",
      email: "buiduy886ty@gmail.com",
      referral: "422ef5",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-28T18:40:48.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-28T18:40:48.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 62,
      userName: "tinvincent",
      email: "tinvincent@gmail.com",
      referral: "eae585",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-02-28T19:34:45.000Z",
      block: 0,
      twofa: 0,
      balance: 30909.5,
      demoBalance: 10000,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-02-28T19:34:45.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 63,
      userName: "dsf",
      email: "sdf",
      referral: "6b64c6",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-03-02T09:40:39.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-03-02T09:40:39.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 64,
      userName: "ewr",
      email: "ewr",
      referral: "55963d",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-03-02T09:41:34.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-03-02T09:41:34.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 65,
      userName: "wer",
      email: "wer",
      referral: "afb703",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-03-02T09:42:01.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-03-02T09:42:01.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 67,
      userName: "testusernameuppercase",
      email: "tihernemfdnfdsnf@gmail.com",
      referral: "58b814",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-03-09T08:56:18.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-03-09T08:56:18.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 68,
      userName: "huynhhuuthiennn",
      email: "hddjsdks@gmail.com",
      referral: "e78886",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-03-09T09:32:39.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-03-09T09:32:39.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 69,
      userName: "títèo",
      email: "keytongtlc@gmail.com",
      referral: "801597",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-03-09T09:35:36.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-03-09T09:35:36.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 70,
      userName: "phixuan",
      email: "maixuanphi555@gmail.com",
      referral: "e5eea9",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-03-30T01:41:30.000Z",
      block: 0,
      twofa: 0,
      balance: 500,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-03-30T01:41:30.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 71,
      userName: "tiutiu",
      email: "tuanh222111222@gmail.com",
      referral: "7d4fe2",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-04-09T16:31:32.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 1,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-04-09T16:31:32.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 72,
      userName: "test300",
      email: "test300@gmail.com",
      referral: "352824",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-06-28T07:17:09.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-06-28T07:17:09.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 73,
      userName: "epp14931",
      email: "epp14931@zbock.com",
      referral: "cf0d2e",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-06-28T07:40:25.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-06-28T07:40:25.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 74,
      userName: "xrt57977",
      email: "xrt57977@nezid.com",
      referral: "6eca6d",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-06-28T07:45:56.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-06-28T07:45:56.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 75,
      userName: "huytran",
      email: "thehuytgvn@gmail.com",
      referral: "60921d",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-06-29T16:59:07.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-06-29T16:59:07.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 76,
      userName: "dohuuhien",
      email: "dohuuhien2609@gmail.com",
      referral: "6a9810",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-04T05:02:58.000Z",
      block: 0,
      twofa: 0,
      balance: 18.5,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 160,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 160,
      updateLevel_at: "2023-07-04T05:02:58.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 77,
      userName: "gigi123",
      email: "q.anh3009@gmail.com",
      referral: "80e1f7",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-04T05:04:13.000Z",
      block: 0,
      twofa: 0,
      balance: 123,
      demoBalance: 1000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 270,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 270,
      updateLevel_at: "2023-07-04T05:04:13.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 78,
      userName: "luongquan",
      email: "wanluong@gmail.com",
      referral: "e816a1",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-07-12T04:17:34.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-12T04:17:34.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 79,
      userName: "huynhhuuthien",
      email: "0376601242",
      referral: "c9268a",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T06:31:15.000Z",
      block: 0,
      twofa: 1,
      balance: 0,
      demoBalance: 1000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T06:31:15.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: "/images/image-1689318367859.png",
    },
    {
      id: 80,
      userName: "wankute",
      email: "0327644518",
      referral: "d79220",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:06:15.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:06:15.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 81,
      userName: "quanluong",
      email: "123456789",
      referral: "4ce885",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-07-14T09:06:31.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:06:31.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 82,
      userName: "usertestuser",
      email: "0766666407",
      referral: "1d40c5",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:08:35.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:08:35.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 83,
      userName: "usertestusera",
      email: "07666664071",
      referral: "b57820",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:14:10.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:14:10.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 84,
      userName: "oaiana",
      email: "091827277",
      referral: "978550",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:14:33.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:14:33.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 85,
      userName: "test1242",
      email: "0376601243",
      referral: "a685b7",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:15:38.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:15:38.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 86,
      userName: "test231613",
      email: "0376601244",
      referral: "0883b3",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:18:10.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 1,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:18:10.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 87,
      userName: "test212546",
      email: "0376601245",
      referral: "d0acc5",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:18:44.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 1,
      trade: 0,
      double10: 1,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:18:44.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 88,
      userName: "testsdt",
      email: "0908433123",
      referral: "c2c5d4",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-14T09:25:45.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-14T09:25:45.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 89,
      userName: "luongquan112",
      email: "01254225616",
      referral: "c8448c",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-17T03:51:29.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-17T03:51:29.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 90,
      userName: "kientestwal",
      email: "0934827815",
      referral: "daaf6b",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-17T03:54:08.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-17T03:54:08.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 91,
      userName: "huyhuy",
      email: "0986950509",
      referral: "b761dc",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-17T03:56:17.000Z",
      block: 0,
      twofa: 0,
      balance: 300000,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-17T03:56:17.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 92,
      userName: "bacunon123",
      email: "bacunon123@gmail.com",
      referral: "baacb3",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-07-30T10:37:41.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-07-30T10:37:41.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 93,
      userName: "test100gmailcom",
      email: "testf31@gmail.com",
      referral: "5c3e52",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-08-01T07:31:13.000Z",
      block: 0,
      twofa: 0,
      balance: 123585000.1855,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 301720.09,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 301720.09,
      updateLevel_at: "2023-08-01T07:31:13.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 94,
      userName: "hoangphuc",
      email: "phamquangkhai594@gmail.com",
      referral: "fbc6c9",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-08-01T12:31:14.000Z",
      block: 0,
      twofa: 1,
      balance: 300016.35,
      demoBalance: 1009.5,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 175,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 175,
      updateLevel_at: "2023-08-01T12:31:14.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 95,
      userName: "vnds0112",
      email: "akefounder@gmail.com",
      referral: "73e386",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-08-01T12:48:05.000Z",
      block: 0,
      twofa: 0,
      balance: 0.1,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-08-01T12:48:05.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 96,
      userName: "president9999",
      email: "caothang.bct@gmail.com",
      referral: "3da38a",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-08-09T07:07:24.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-08-09T07:07:24.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 97,
      userName: "vndsake",
      email: "vndsfounderscofounders@gmail.com",
      referral: "f9e658",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-08-11T05:05:06.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 0,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 1,
      trade: 0,
      double10: 1,
      marketing: 1,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-08-11T05:05:06.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 98,
      userName: "thanhhai",
      email: "buithanhhaibl1984@gmail.com",
      referral: "d4a358",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-08-22T05:26:16.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-08-22T05:26:16.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 99,
      userName: "testtt1gmailcom",
      email: "testtt1@gmail.com",
      referral: "61663f",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-09-18T04:03:54.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-09-18T04:03:54.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 100,
      userName: "testt1",
      email: "testt1@gmail.com",
      referral: "63fa97",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-09-23T04:20:04.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-09-23T04:20:04.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 103,
      userName: "truong",
      email: "ceothanhnien@gmail.com",
      referral: "d7c9e1",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-09-25T04:03:20.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-09-25T04:03:20.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 104,
      userName: "testlan1",
      email: "testlan1@gmail.com",
      referral: "79ad66",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-09-27T06:44:23.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-09-27T06:44:23.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 105,
      userName: "deemoo",
      email: "deemoo@gmail.com",
      referral: "ce93fb",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-09-28T03:15:02.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-09-28T03:15:02.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 106,
      userName: "chotinh1505",
      email: "chotinh1505@gmail.com",
      referral: "36bf09",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-10-16T01:24:36.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-10-16T01:24:36.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 107,
      userName: "natelee",
      email: "natelee2504@gmail.com",
      referral: "d6f509",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-10-16T01:27:15.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 2147483647,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-10-16T01:27:15.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 108,
      userName: "namnam",
      email: "aaaaa@gmail.com",
      referral: "15a69f",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-10-16T04:53:20.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-10-16T04:53:20.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 109,
      userName: "thananm",
      email: "thanhnam@gmail.com",
      referral: "7f8b5f",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-10-16T04:53:43.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-10-16T04:53:43.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 110,
      userName: "thananm13",
      email: "thanhnam12@gmail.com",
      referral: "86ffaf",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-10-16T04:53:56.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-10-16T04:53:56.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 111,
      userName: "testtttt",
      email: "test2@gmail.com",
      referral: "65835b",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-11-22T08:53:56.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-11-22T08:53:56.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 112,
      userName: "thisistest201",
      email: "test201@gmail.com",
      referral: "8e3c7d",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-11-22T08:54:44.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-11-22T08:54:44.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 113,
      userName: "krz28353",
      email: "krz28353@nezid.com",
      referral: "53277a",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-11-24T02:18:54.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-11-24T02:18:54.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 114,
      userName: "dgxa9999",
      email: "dgxa9999@gmail.com",
      referral: "64e412",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-11-24T02:19:27.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 0,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-11-24T02:19:27.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 115,
      userName: "juw78037",
      email: "juw78037@nezid.com",
      referral: "c797df",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-11-24T02:24:04.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 1,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-11-24T02:24:04.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 116,
      userName: "ooj16779",
      email: "ooj16779@zbock.com",
      referral: "d584b0",
      parentId: 1,
      userNameParent: "admin",
      active: 0,
      created_at: "2023-11-24T02:27:31.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 0,
      double10: 0,
      marketing: 1,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-11-24T02:27:31.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
    {
      id: 117,
      userName: "kog90409",
      email: "kog90409@nezid.com",
      referral: "c3c433",
      parentId: 1,
      userNameParent: "admin",
      active: 1,
      created_at: "2023-11-24T02:36:24.000Z",
      block: 0,
      twofa: 0,
      balance: 0,
      demoBalance: 10000,
      level: 0,
      commissionBalance: 0,
      deposit: 0,
      blockLevel: 0,
      trade: 1,
      double10: 1,
      marketing: 1,
      type: 0,
      admin: 0,
      totalOrder: 0,
      totalOrderF1: 0,
      commissionMemberVip: 0,
      totalMemberVip: 0,
      totalMemberVipF1: 0,
      totalMember: 0,
      beforeTotalOrder: 0,
      updateLevel_at: "2023-11-24T02:36:24.000Z",
      totalCommission: 0,
      beforeCommission: 0,
      avatar: null,
    },
  ];
  const renderTree = (dataTree) => {};
  return (
    <div className="admin-dashboard">
      <h3 className="admin-dashboard__title">Network</h3>
      <div>{renderTree(dataTree)}</div>
    </div>
  );
}

export default Dashboard;
