export const coin = {
  createWallet: "coin/createWallet",
  totalValue: "coin/totalValue",
  setCoin: "coin/setCoin",
  setAmountCoin: "coin/setAmountCoin",
};

export const defaultState = {
  coin: "BTC",
  amountCoin: 0,
  createWallet: 0, // mỗi lần tạo ví thì tăng biến này lên 1 -> load lại những component liên quan
  totalValue: 0,
};

export const getCoinCreateWallet = (state) => state.coinReducer.createWallet;
export const getCoinTotalValue = (state) => state.coinReducer.totalValue;
export const getCoinAll = (state) => state.coinReducer.totalValue;
export const getAmountCoin = (state) => state.coinReducer.amountCoin;
export const getCoin = (state) => state.coinReducer.coin;
