import {
  swaptobeWallet,
  defaultState,
  actionContent,
} from "../constant/SwaptobeWallet.constant";

export const swaptobeWalletReducer = (state = defaultState, action) => {
  switch (action.type) {
    case swaptobeWallet.setShowMain:
      return {
        ...state,
        selectedCurrency: "",
        showContent: actionContent.main,
      };

    case swaptobeWallet.setShowWithdraw:
      return {
        ...state,
        selectedCurrency: action.payload,
        showContent: actionContent.withdraw,
      };
    case swaptobeWallet.setShowDeposite:
      return {
        ...state,
        selectedCurrency: action.payload,
        showContent: actionContent.desposite,
      };

    default:
      return state;
  }
};
