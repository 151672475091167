export const localStorageVariable = {
  lng: "lng",
  token: "token",
  user: "user",
};

export const url = {
  home: "/",
  signup: "/signup",
  profile: "/profile",
  wallet: "/wallet-2",
  swap: "/swap",
  login: "/login",
};

export const api_url = {
  login: "api/user/login",
  refreshToken: "api/user/refreshToken",
};

export const showAlertType = {
  success: "success",
  error: "error",
};

export const api_status = {
  pending: "pending",
  fetching: "fetching",
  fulfilled: "fulfilled",
  rejected: "rejected",
};
