import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18n, { availableLanguage } from "src/translation/i18n";
import {
  swaptoveWalletShowDepositeActionCreator,
  swaptoveWalletShowWithdrawActionCreator,
} from "src/redux/actions/SwaptobeWallet.action";
import { api_status, localStorageVariable, url } from "src/constant";
import { formatStringNumberCultureUS, getLocalStorage } from "src/util/common";
import socket from "src/util/socket";
import { DOMAIN } from "src/util/service";
import { Spin } from "antd";
import { getWalletApi } from "src/util/userCallApi";
import {
  coinSetAmountCoin,
  coinSetCoin,
  coinTotalValue,
} from "src/redux/actions/Coin.action";
import { useHistory } from "react-router-dom";
function SwaptobeWalletList() {
  //
  const history = useHistory();
  const [allCoin, setAllCoin] = useState();
  const [, setCallApiStatus] = useState(api_status.pending);
  const [myListCoin, setMyListCoin] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    //lay ngon ngu khi load page
    const language =
      getLocalStorage(localStorageVariable.lng) || availableLanguage.vi;
    i18n.changeLanguage(language);
    // lang nghe socket
    socket.on("listCoin", (res) => {
      setAllCoin(res);
      //
    });
    //
    fetchMyListCoin();
  }, []);

  useEffect(() => {
    if (myListCoin.length && allCoin) {
      let result = 0;
      myListCoin.forEach((item) => {
        const name = Object.keys(item)[0];
        const number = Object.values(item)[0];
        const itemsCoin = allCoin.filter((item) => item.name === name);
        if (itemsCoin.length) {
          result += number * itemsCoin[0].price;
        }
      });
      dispatch(coinTotalValue(result));
    }
  }, [myListCoin, allCoin]);
  //
  const getMyCoin = function (coinName) {
    if (myListCoin.length) {
      const obj = myListCoin.find((obj) => obj.hasOwnProperty(coinName));
      return obj ? obj[coinName] : 0;
    }
  };
  const fetchMyListCoin = function () {
    setCallApiStatus(api_status.fetching);
    getWalletApi()
      .then((resp) => {
        const dataResp = resp.data.data;
        const result = [];
        if (dataResp) {
          Object.keys(dataResp).forEach((item) => {
            const key = item.toString().replace("_balance", "").toUpperCase();
            const value = +dataResp[item];
            const newOjb = { [key]: value };
            result.push(newOjb);
          });
        }
        setMyListCoin(result);
        setCallApiStatus(api_status.fulfilled);
      })
      .catch((error) => {
        setCallApiStatus(api_status.rejected);
        console.log(error);
      });
  };
  const swapOnClickHandle = function (coinName, amount) {
    dispatch(coinSetCoin(coinName));
    dispatch(coinSetAmountCoin(amount));
    history.push(url.swap);
  };
  const renderListCurrency = (listCurrencyData) =>
    listCurrencyData?.map((item) => (
      <li key={item.token_key} className="list-item">
        <div className="name">
          <img src={DOMAIN + item.image} alt=".." />
          <span>{item.name}</span>
          <div>{item.token_key}</div>
        </div>
        <div className="price">
          <span>$ {formatStringNumberCultureUS(item.price.toString())}</span>
          <span>Own: {getMyCoin(item.name)} coins</span>
        </div>
        <div className="action">
          <button
            onClick={() => {
              dispatch(swaptoveWalletShowDepositeActionCreator(item.name));
              window.scrollTo(0, 0);
            }}
            className="primary-button"
          >
            {t("deposit")}
          </button>
          <button
            onClick={() => {
              dispatch(swaptoveWalletShowWithdrawActionCreator(item.key));
              window.scrollTo(0, 0);
            }}
            className="seconary-button"
          >
            {t("withdraw")}
          </button>
          <button
            onClick={() => swapOnClickHandle(item.name, getMyCoin(item.name))}
            className="seconary-button"
          >
            {t("swap")}
          </button>
        </div>
      </li>
    ));
  //
  return (
    <div className="swaptobeWalletList">
      <ul className="list">
        {!allCoin && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Spin style={{ margin: "auto" }}></Spin>
          </div>
        )}
        {renderListCurrency(allCoin)}
      </ul>
    </div>
  );
}
export default SwaptobeWalletList;
