import { defaultState, coin } from "../constant/Coin.constant";

export const coinReducer = (state = defaultState, action) => {
  switch (action.type) {
    case coin.setCoin: {
      return {
        ...state,
        coin: action.payload,
      };
    }
    case coin.createWallet: {
      let cre = state.createWallet;
      return {
        ...state,
        createWallet: ++cre,
      };
    }
    case coin.totalValue: {
      return {
        ...state,
        totalValue: action.payload,
      };
    }
    case coin.setAmountCoin: {
      return {
        ...state,
        amountCoin: action.payload,
      };
    }
    default:
      return state;
  }
};
