import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { formatStringNumberCultureUS, getLocalStorage } from "src/util/common";
import { useSelector } from "react-redux";
import { getSelectedCurrency } from "src/redux/constant/SwaptobeWallet.constant";
import i18n, { availableLanguage } from "src/translation/i18n";
import { localStorageVariable } from "src/constant";
function FormWithdraw() {
  //
  const form = {
    WalletEth: "walletEth",
    Aliases: "Aliases",
  };
  const withdrawTypeEnum = {
    TRC20: "TRC20",
    ERC20: "ERC20",
    BEP20: "BEP20",
  };
  //
  const [showForm, setShowForm] = useState(form.WalletEth);
  const { t } = useTranslation();
  const [withdrawType, setWithdrawType] = useState(withdrawTypeEnum.TRC20);
  const [inputAmountCurrency, setInputAmountCurrency] = useState("");
  useEffect(() => {
    const language =
      getLocalStorage(localStorageVariable.lng) || availableLanguage.vi;
    i18n.changeLanguage(language);
  }, []);
  //
  const headerItemClickHandle = function (value) {
    setShowForm(() => value);
    setInputAmountCurrency("");
  };
  const inputAmountCurrencyOnChangeHandles = function (e) {
    const inputValue = e.target.value;
    const inputValueWithoutComma = inputValue.replace(/,/g, "");
    const regex = /^$|^[0-9]+(\.[0-9]*)?$/;
    if (!regex.test(inputValueWithoutComma)) {
      setInputAmountCurrency(inputValue.slice(0, -1));
      return;
    }
    const inputValueFormated = formatStringNumberCultureUS(
      inputValueWithoutComma
    );
    setInputAmountCurrency(inputValueFormated);
  };

  return (
    <div className="container">
      <div className="FormWithdraw">
        <div className="left">
          <div className="header">
            <span
              onClick={() => {
                headerItemClickHandle(form.WalletEth);
              }}
              className={showForm === form.WalletEth ? "active" : ""}
            >
              Wallet {useSelector(getSelectedCurrency)}
            </span>
            <span
              onClick={() => {
                headerItemClickHandle(form.Aliases);
              }}
              className={showForm === form.Aliases ? "active" : ""}
            >
              Aliases
            </span>
          </div>
          <form
            className={`wallet ${
              showForm !== form.WalletEth ? "--d-none" : ""
            }`}
          >
            <div className="withdraw-type">
              <span
                onClick={() => setWithdrawType("TRC20")}
                className={`withdraw-type-items ${
                  withdrawType === "TRC20" ? "active" : ""
                }`}
              >
                TRC20
              </span>
              <span
                onClick={() => setWithdrawType("ERC20")}
                className={`withdraw-type-items ${
                  withdrawType === "ERC20" ? "active" : ""
                }`}
              >
                ERC20
              </span>
              <span
                onClick={() => setWithdrawType("BEP20")}
                className={`withdraw-type-items ${
                  withdrawType === "BEP20" ? "active" : ""
                }`}
              >
                BEP20
              </span>
            </div>
            <div className="input">
              <p>Wallet {useSelector(getSelectedCurrency)} - Note *</p>
              <input type="text" />
            </div>
            <div className="input">
              <p>
                {t("amountOf")} {useSelector(getSelectedCurrency)}
              </p>
              <input
                value={inputAmountCurrency}
                onChange={inputAmountCurrencyOnChangeHandles}
                type="text"
              />
              <div className="list-tag">
                <span>{useSelector(getSelectedCurrency)}</span>
                <span className="active">MAX</span>
              </div>
            </div>
            <div className="max-available">
              <span>{t("maxAvailable")}:</span>{" "}
              <span>0 {useSelector(getSelectedCurrency)}</span>
            </div>
            <ul className="list-notify">
              <li className="notify-item">
                <span>
                  <img src="./img/!.png" alt="" />
                </span>
                <p>
                  {t(
                    "youMustKeepAMinimumOf20TRXInYourWalletToSecureEnoughGasFeesForTradingTRC20Tokens"
                  )}
                </p>
              </li>
              <li className="notify-item">
                <span>
                  <img src="./img/!.png" alt="" />
                </span>
                <p>
                  {t(
                    "youMustKeepAMinimumOf20TRXInYourWalletToSecureEnoughGasFeesForTradingTRC20Tokens"
                  )}
                </p>
              </li>
              <li className="notify-item">
                <span>
                  <img src="./img/!.png" alt="" />
                </span>
                <p>
                  {t(
                    "theOverheadFeesAreNotFixedSubjectToChangeDependingOnTheStateOfTheBlockchainNetworks"
                  )}
                </p>
              </li>
              <li className="notify-item">
                <span>
                  <img src="./img/!.png" alt="" />
                </span>
                <p>{t("estimatedCompletionTime2Minutes")}</p>
              </li>
            </ul>
            <div className="button-submit-container">
              <button>{t("send")}</button>
            </div>
          </form>
          <form
            className={`aliases ${showForm !== form.Aliases ? "--d-none" : ""}`}
          >
            <div className="input">
              <p>{t("userName")}</p>
              <input type="text" />
            </div>
            <div className="input">
              <p>{t("amountOf")} USDT</p>
              <input
                value={inputAmountCurrency}
                onChange={inputAmountCurrencyOnChangeHandles}
                type="text"
              />
              <div className="list-tag">
                <span>USDT</span>
              </div>
            </div>
            <div className="input">
              <p>{t("message")}</p>
              <textarea
                placeholder="I'm fine, tks. And u!"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className="button-submit-container">
              <button>{t("send")}</button>
            </div>
          </form>
        </div>
        <div className="right">
          <div className="loading">
            <Spin />
          </div>
          <ul className="paging">
            <span className="disable prev">{"<"}</span>
            <span className="page">1</span>
            <span className="disable next">{">"}</span>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default FormWithdraw;
