export const setLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};
export const getLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? JSON.stringify(""));
  } catch (e) {
    return null;
  }
};
export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
};

/**
 * Hàm nhận vào một chuỗi số với định dạng theo kiểu US (dấu chấm phân tách phần thập phân)
 * Hàm định dạng lại chuỗi số bằng cách thêm dấu phẩy vào phần thập phân phía trước dấu chấm
 * Nếu truyền một chuỗi không đúng định dạng thì hàm lặp tức dừng thực thi và return undefine
 * @numberString {string} stringValue chuỗi số.
 * @returns {string} - chuỗi số sau khi đã thêm dấu phẩy.
 */
export const formatStringNumberCultureUS = (numberString) => {
  const regex = /^$|^[0-9]+(\.[0-9]*)?$/;
  if (!regex.test(numberString)) return;
  const parts = numberString.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
export const convertStringToNumber = function (str) {
  var noCommas = str.replace(/,/g, "");
  var number = Number(noCommas);
  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
};
