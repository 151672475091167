export const actionContent = {
  main: "main",
  desposite: "desposite",
  withdraw: "withdraw",
};

export const defaultState = {
  selectedCurrency: "",
  showContent: actionContent.main,
};
export const swaptobeWallet = {
  setShowMain: "swaptobeWallet/setShowMain",
  setShowWithdraw: "swaptobeWallet/setShowWithdraw",
  setShowDeposite: "swaptobeWallet/setShowDeposite",
};

export const getSelectedCurrency = (state) =>
  state.swaptobeWalletReducer.selectedCurrency;

export const getShowContent = (state) =>
  state.swaptobeWalletReducer.showContent;
