import { Modal, Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  convertStringToNumber,
  formatStringNumberCultureUS,
  getLocalStorage,
} from "src/util/common";
import { localStorageVariable, url } from "src/constant";
import i18n, { availableLanguage } from "src/translation/i18n";
import { useTranslation } from "react-i18next";
import socket from "src/util/socket";
import { useSelector } from "react-redux";
import { DOMAIN } from "src/util/service";
import { useHistory } from "react-router-dom";
import { getAmountCoin, getCoin } from "src/redux/constant/Coin.constant";
export default function Swap() {
  //
  const { isLogin } = useSelector((root) => root.loginReducer);
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [swapFromCoin, setSwapFromCoin] = useState(useSelector(getCoin));
  const [swapToCoin, setSwapToCoin] = useState("USDT");
  const amountCoin = useSelector(getAmountCoin);
  const [fromCoinValue, setFromCoinValue] = useState(
    amountCoin > 0 ? amountCoin.toString() : ""
  );
  const [toCoinValue, setToCoinValue] = useState("");
  const [searchCoinName, setSearchCoinName] = useState("");
  const searchCoinResult = useRef();
  useEffect(() => {
    socket.on("listCoin", (res) => {
      setData(res);
      setData2(res);
    });
    //
    const language =
      getLocalStorage(localStorageVariable.lng) || availableLanguage.vi;
    i18n.changeLanguage(language);
    //
    const element = document.querySelector(".swap");
    element.classList.add("fadeInBottomToTop");
  }, []);
  useEffect(() => {
    if (data.length > 0) {
      setToCoinValueCalc();
    }
  }, [fromCoinValue, swapFromCoin, swapToCoin, data]);
  //
  const { t } = useTranslation();
  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => {
    setSearchCoinName("");
    setIsModalVisible(false);
  };
  const showModal2 = () => setIsModalVisible2(true);
  const handleOk2 = () => setIsModalVisible2(false);
  const handleCancel2 = () => {
    setSearchCoinName("");
    setIsModalVisible2(false);
  };
  const transfer = () => {
    const currentFrom = swapFromCoin;
    const currentTo = swapToCoin;
    setSwapFromCoin(currentTo);
    setSwapToCoin(currentFrom);
  };
  const fromCoinOnChange = function (e) {
    const inputValue = e.target.value;
    const inputValueWithoutComma = inputValue.replace(/,/g, "");
    const regex = /^$|^[0-9]+(\.[0-9]*)?$/;
    if (!regex.test(inputValueWithoutComma)) {
      setFromCoinValue(inputValue.slice(0, -1));
      return;
    }
    const inputValueFormated = formatStringNumberCultureUS(
      inputValueWithoutComma
    );
    setFromCoinValue(inputValueFormated);
    //
  };
  const setToCoinValueCalc = function () {
    if (data.length > 0) {
      const fromCoin = convertStringToNumber(fromCoinValue);
      const fromPrice = data.filter((item) => item.name === swapFromCoin)[0]
        .price;
      const toPrice = data.filter((item) => item.name === swapToCoin)[0].price;
      const result = convertCacl(fromCoin, fromPrice, toPrice);
      setToCoinValue(formatStringNumberCultureUS(result.toString()));
    }
  };
  const coinPriceDifference = function () {
    if (data.length > 0) {
      return formatStringNumberCultureUS(
        convertCacl(
          1,
          data.filter((item) => item.name === swapFromCoin)[0].price,
          data.filter((item) => item.name === swapToCoin)[0].price
        ).toString()
      );
    }
  };
  const convertCacl = function (inputValue, fromPrice, toPrice) {
    return (inputValue * fromPrice) / toPrice;
  };
  const searchCoinOnChange = function (e) {
    setSearchCoinName(e.target.value);
  };
  const mainButtonOnClickHandle = function () {
    if (isLogin) {
    } else {
      history.push(url.login);
    }
  };
  return (
    <div className="swap">
      <div className="container">
        <div className="box">
          <h2 className="title">{t("swap")}</h2>
          <div className="field">
            <label>
              {t("amountOf")} {swapFromCoin}
            </label>
            <div className="input-area">
              <div style={{ flex: 1 }}>
                <input
                  style={{ paddingRight: "55px" }}
                  className="swap__input"
                  value={fromCoinValue}
                  onChange={fromCoinOnChange}
                />
                <button className="max">{t("max")}</button>
              </div>
              <button className="selectBtn" onClick={showModal}>
                <div className="selectBtn-container">
                  <img
                    src={`https://remitano.dk-tech.vn/images/${swapFromCoin}.png`}
                    alt="swapFromCoin"
                  />
                  {swapFromCoin}
                  <i className="fa-solid fa-caret-down"></i>
                </div>
              </button>
            </div>
          </div>
          <div style={{ textAlign: "center", margin: 20 }}>
            <Button
              type="primary"
              shape="circle"
              onClick={transfer}
              size="large"
            >
              <i className="fa-solid fa-up-down" style={{ fontSize: 16 }}></i>
            </Button>
          </div>
          <div className="field">
            <label>
              {t("amountOf")} {swapToCoin}
            </label>
            <div className="input-area">
              <input className="swap__input" value={toCoinValue} readOnly />
              <button className="selectBtn" onClick={showModal2}>
                <div className="selectBtn-container">
                  <img
                    src={`https://remitano.dk-tech.vn/images/${swapToCoin}.png`}
                    alt="swapToCoin"
                  />
                  {swapToCoin}
                  <i className="fa-solid fa-caret-down"></i>
                </div>
              </button>
            </div>
            <span style={{ display: "block", marginTop: 8 }}>
              1 {swapFromCoin} = {coinPriceDifference()} {swapToCoin}
            </span>
          </div>
          <button
            onClick={mainButtonOnClickHandle}
            className="buyBtn"
            size="large"
            type="primary"
          >
            {isLogin ? t("buy") + " " + swapToCoin : t("login")}
          </button>
        </div>
        <div className="box" style={{ marginTop: 30 }}>
          <h2 className="title">{t("swapOrder")}</h2>
          fdsafdsafdsa
        </div>
      </div>
      <Modal
        title="Swap from"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ padding: 20 }}>
          <input
            className="swap__modal-search"
            type="text"
            value={searchCoinName}
            placeholder="Enter the coin name."
            onChange={searchCoinOnChange}
          />
          <div ref={searchCoinResult}>
            {data
              .filter((item) =>
                item.name.toLowerCase().includes(searchCoinName.toLowerCase())
              )
              .map((item, i) => {
                return (
                  <Button
                    className="btn-choice-coin"
                    type={item.name === swapFromCoin ? "primary" : "default"}
                    key={i}
                    onClick={() => {
                      setSwapFromCoin(item.name);
                      setIsModalVisible(false);
                      setSearchCoinName("");
                    }}
                  >
                    <div className="btn-choice-coin-content">
                      <img src={DOMAIN + item.image} alt="item.image" />
                      <span>{item.name}</span>
                    </div>
                  </Button>
                );
              })}
          </div>
        </div>
      </Modal>
      <Modal
        title="Swap to"
        open={isModalVisible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
      >
        <div style={{ padding: 20 }}>
          <input
            className="swap__modal-search"
            type="text"
            value={searchCoinName}
            placeholder="Enter the coin name."
            onChange={searchCoinOnChange}
          />
          {data2
            .filter((item) =>
              item.name.toLowerCase().includes(searchCoinName.toLowerCase())
            )
            .map((item, i) => {
              return (
                <Button
                  className="btn-choice-coin"
                  type={item.name === swapToCoin ? "primary" : "default"}
                  key={i}
                  onClick={() => {
                    setSwapToCoin(item.name);
                    setSearchCoinName("");
                    setIsModalVisible2(false);
                  }}
                >
                  <div className="btn-choice-coin-content">
                    <img src={DOMAIN + item.image} alt="item.image" />
                    <span>{item.name}</span>
                  </div>
                </Button>
              );
            })}
        </div>
      </Modal>
    </div>
  );
}
