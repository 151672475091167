import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { showAlert } from "../function/showAlert";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../function/showToast";
import { useEffect, useRef } from "react";
import { api_status, url } from "src/constant";
import { getWalletApi } from "src/util/userCallApi";
import { Spin } from "antd";
export default function Header1({ history }) {
  //
  const { isLogin, username } = useSelector((root) => root.loginReducer);
  const createdWallet = useSelector((root) => root.coinReducer.createWallet);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [callApiStatus, setCallApiStatus] = useState(api_status.pending);
  const [listOwnedCoins, setListOwnedCoins] = useState([]);
  const userMenuElement = useRef();
  const walletMenuElement = useRef();
  function loadListMyCoin() {
    setCallApiStatus(api_status.fetching);
    const listOwnedCoins = getWalletApi();
    listOwnedCoins
      .then((resp) => {
        setCallApiStatus(api_status.fulfilled);
        const result = [];
        const dataResp = resp.data.data;
        if (dataResp) {
          Object.keys(dataResp).forEach((item) => {
            const key = item.toString().replace("_balance", "");
            const value = +dataResp[item];
            const newOjb = { [key]: value };
            result.push(newOjb);
          });
        }
        setListOwnedCoins(result);
      })
      .catch((error) => {
        setCallApiStatus(api_status.rejected);
        showAlert("error", "Không tải được dữ liệu coin đã sở hữu.");
      });
  }

  useEffect(() => {
    if (isLogin) {
      loadListMyCoin();
    }
    //
    const element = document.querySelector(".header1");
    element.classList.add("fadeInTopToBottom");
    //
    window.addEventListener("click", closeAllSubMenu);
    //
    return () => {
      window.removeEventListener("click", closeAllSubMenu);
    };
  }, []);
  useEffect(() => {
    if (isLogin) {
      loadListMyCoin();
    }
  }, [createdWallet]);
  //
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    history.push("/");
    dispatch({ type: "USER_LOGOUT" });
    showToast("success", "Logged out");
  };
  const userNameOnClickHandle = function (e) {
    e.stopPropagation();
    userMenuElement.current.classList.toggle("show");
    walletMenuElement.current.classList.remove("show");
  };
  const walletOnClickHandle = function (e) {
    e.stopPropagation();
    walletMenuElement.current.classList.toggle("show");
    userMenuElement.current.classList.remove("show");
  };
  const closeAllSubMenu = function () {
    if (userMenuElement.current && walletMenuElement.current) {
      userMenuElement.current.classList.remove("show");
      walletMenuElement.current.classList.remove("show");
    }
  };
  return (
    <header className="header1">
      <div className="container">
        {isLogin ? (
          <>
            <div className="header1__item" onClick={walletOnClickHandle}>
              {t("wallets")}
              <div ref={walletMenuElement} className="header1__subMenu wallet">
                <div className="header1__subMenu__user-info">
                  <div>
                    {t("totalValue")} <i className="fa-regular fa-eye"></i>
                  </div>
                  <div className="header1__list-coin">
                    {callApiStatus === api_status.fetching ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100px",
                          width: "100%",
                        }}
                      >
                        <Spin />
                      </div>
                    ) : (
                      listOwnedCoins.map((item) => (
                        <span
                          className={`header1__coin-item ${
                            +Object.values(item)[0] > 0 ? "active" : ""
                          }`}
                          key={Object.keys(item)[0]}
                        >
                          {Object.keys(item)[0].toUpperCase()}:{" "}
                          {Object.values(item)[0]}
                        </span>
                      ))
                    )}
                  </div>
                  <div>0 VND</div>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    walletMenuElement.current.classList.remove("show");
                    history.push(url.wallet);
                  }}
                  className="header1__subMenu-item"
                >
                  {t("wallets")}
                </div>
                <div className="header1__subMenu-item">{t("p2PHistory")} </div>
                <div className="header1__subMenu-item">
                  {t("instantTradeHistory")}
                </div>
                <div className="header1__subMenu-item">{t("swapHistory")}</div>
                <div className="header1__subMenu-item">
                  {t("waveRidingHistory")}
                </div>
              </div>
            </div>
            <div className="header1__item" onClick={userNameOnClickHandle}>
              {username}
              <div ref={userMenuElement} className="header1__subMenu">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    userMenuElement.current.classList.remove("show");
                    history.push(url.profile);
                  }}
                  className="header1__subMenu-item"
                >
                  <i className="fa-regular fa-user"></i>
                  {t("profile")}
                </div>
                <div onClick={logout} className="header1__subMenu-item">
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  {t("logOut")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="header1__item" onClick={() => history.push("/login")}>
            {t("login")} / {t("register")}
          </div>
        )}
      </div>
    </header>
  );
}
