import { swaptobeWallet } from "../constant/SwaptobeWallet.constant";

export const swaptoveWalletShowMainActionCreator = function () {
  return {
    type: swaptobeWallet.setShowMain,
  };
};

export const swaptoveWalletShowWithdrawActionCreator = function (payload) {
  return {
    type: swaptobeWallet.setShowWithdraw,
    payload,
  };
};

export const swaptoveWalletShowDepositeActionCreator = function (payload) {
  return {
    type: swaptobeWallet.setShowDeposite,
    payload,
  };
};
