import { coin } from "../constant/Coin.constant";

export const coinCreateWallet = function () {
  return {
    type: coin.createWallet,
  };
};

export const coinTotalValue = function (payload) {
  return {
    type: coin.totalValue,
    payload,
  };
};

export const coinSetCoin = function (payload) {
  return {
    type: coin.setCoin,
    payload,
  };
};

export const coinSetAmountCoin = function (payload) {
  return {
    type: coin.setAmountCoin,
    payload,
  };
};
